export const siteURL = "https://puzzle.dev/"
export const siteTitle = "パズル製作研究所"
export const siteLogo = "/logo.png"

export const techniquesURL = "techniques/"
export const authorName = "人見 琢也"
export const publisherName = "パズル製作研究所"
export const twitterUserName = "takuchan"
export const facebookAppId = "2336777883108347"

export const API_GATEWAY_URL =
  "https://lwz9ngblxj.execute-api.ap-northeast-1.amazonaws.com/prod"
export const API_KEY = "gU7RCWLnBmV7blG7aoAMnHY1W9puBT7xflK125b0"
