import React from "react"
import _ from "lodash"
import clsx from "clsx"
import { lighten, makeStyles } from "@material-ui/core/styles"
import Checkbox from "@material-ui/core/Checkbox"
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"

import { useStyles } from "../utils/styles"

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    minHeight: 0,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
        },
  title: {
    flex: "1 1 100%",
  },
}))

const EnhancedTableToolbar = ({ numSelected, handleDeleteClick }) => {
  const classes = useToolbarStyles()

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          gutterBottom
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          gutterBottom
        >
          問題リスト
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  )
}

const sortByDifficulty = sudokus => {
  const lexicographyicOrder = (ary0, ary1) => {
    if (_.isEmpty(ary0) || _.isEmpty(ary1)) {
      return 0
    }
    if (_.head(ary0) < _.head(ary1)) {
      return -1
    }
    if (_.head(ary0) > _.head(ary1)) {
      return 1
    }
    return lexicographyicOrder(_.tail(ary0), _.tail(ary1))
  }

  return sudokus.sort((a, b) => {
    const revArrayA = _.reverse(difficultyToArray(a.difficulty))
    const revArrayB = _.reverse(difficultyToArray(b.difficulty))
    return lexicographyicOrder(revArrayA, revArrayB)
  })
}

const topmostTactic = difficulty => {
  return _.reduce(
    difficultyToArray(difficulty),
    (acc, value, index) => {
      return value > 0 ? index : acc
    },
    0
  )
}

const difficultyToArray = difficulty => {
  return [
    difficulty.tactic0,
    difficulty.tactic1,
    difficulty.tactic2,
    difficulty.tactic3,
    difficulty.tactic4,
    difficulty.tactic5,
    difficulty.tactic6,
    difficulty.tactic7,
    difficulty.tactic8,
    difficulty.tactic9,
    difficulty.tactic10,
    difficulty.tactic11,
    difficulty.tactic12,
  ]
}

export default function SudokuList({
  sudokus,
  handleDelete,
  selected,
  setSelected,
}) {
  const classes = useStyles()
  const rowCount = sudokus.length
  const sorted = sortByDifficulty(sudokus)

  const handleSelectAllClick = e =>
    setSelected(e.target.checked ? sorted.map(s => s.id) : [])

  const handleClick = (e, id) => {
    const newSelected =
      selected.indexOf(id) === -1
        ? _.concat(selected, [id])
        : _.filter(selected, item => {
            return item !== id
          })
    setSelected(newSelected)
  }
  const isSelected = id => selected.indexOf(id) !== -1
  const handleDeleteClick = e => {
    handleDelete()
  }

  return (
    <Paper className={classes.paper}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleDeleteClick={handleDeleteClick}
      />
      <Table size="small" aria-label="pulication info">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  selected.length > 0 && selected.length < rowCount
                }
                checked={selected.length === rowCount}
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all" }}
              />
            </TableCell>
            <TableCell key="id">ID</TableCell>
            {_.map([...Array(13).keys()], tacticNo => {
              return (
                <TableCell
                  key={`tactic${tacticNo}`}
                  align="right"
                  padding="none"
                >
                  {_.padStart(tacticNo, 2, "\u00A0")}
                </TableCell>
              )
            })}
            <TableCell key="topmost" align="right">
              Tactic
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sorted.map((s, index) => {
            const isItemSelected = isSelected(s.id)
            const labelId = `enhanced-table-checkbox-${index}`

            return (
              <TableRow
                hover
                onClick={e => handleClick(e, s.id)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={s.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {s.id}
                </TableCell>
                {_.map([...Array(13).keys()], tacticNo => {
                  return (
                    <TableCell align="right" padding="none" key={tacticNo}>
                      {_.padStart(
                        difficultyToArray(s.difficulty)[tacticNo],
                        2,
                        "\u00A0"
                      )}
                    </TableCell>
                  )
                })}
                <TableCell align="right">
                  {topmostTactic(s.difficulty)}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}
