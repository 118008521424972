import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import _ from "lodash"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Fab from "@material-ui/core/Fab"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Grid from "@material-ui/core/Grid"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import FileCopyIcon from "@material-ui/icons/FileCopy"

import { useAuth0 } from "./react-auth0-spa"
import { useStyles } from "../utils/styles"
import { fetchApi } from "../utils/fetchApi"
import SudokuList from "./SudokuList"

export default function Problems() {
  const classes = useStyles()
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()
  const [params, setParams] = useState({
    tacticNo: 0,
    size: 10,
  })
  const [messages, setMessages] = useState({
    tacticNo: "",
    size: "",
  })
  const [problems, setProblems] = useState([])
  const [cleanupFlag, setCleanupFlag] = useState(false)
  const [lastTacticNo, setLastTacticNo] = useState(0)
  const [selected, setSelected] = useState([])

  const clearSelected = () => {
    setSelected([])
  }
  const updateField = e => {
    setMessages({ ...messages, [e.target.name]: e.target.validationMessage })
    if (_.isEmpty(e.target.validationMessage)) {
      setParams({ ...params, [e.target.name]: e.target.value })
    }
  }

  const handleGet = e => {
    if (loading || !isAuthenticated) {
      return
    }

    const fetchProblems = async () => {
      const token = await getTokenSilently()
      let memo = problems
      if (
        lastTacticNo === params["tacticNo"] &&
        problems.length >= params["size"]
      ) {
        return
      }
      if (
        lastTacticNo === params["tacticNo"] &&
        problems.length < params["size"]
      ) {
        params["size"] = params["size"] - problems.length
      } else {
        setLastTacticNo(params["tacticNo"])
        memo = []
      }
      const responseData = await fetchApi(token, "/problems", "GET", {}, params)
      if (!cleanupFlag) {
        setProblems(_.concat(memo, responseData))
        clearSelected()
      }
    }
    fetchProblems()
  }

  const handleDelete = () => {
    setProblems(
      _.filter(problems, problem => {
        return selected.indexOf(problem.id) === -1
      })
    )
    clearSelected()
  }

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(JSON.stringify(problems))
    }
  }

  useEffect(() => {
    if (loading || !isAuthenticated) {
      navigate("/dashboard")
      return
    }
    setCleanupFlag(false)
    return () => {
      setCleanupFlag(true)
    }
  }, [loading, isAuthenticated, setCleanupFlag])

  return loading || !isAuthenticated ? null : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} key="fetch_parameters">
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              問題を取得
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl
                  required
                  fullWidth
                  error={!_.isEmpty(messages.tacticNo)}
                >
                  <InputLabel id="tacticNo_label">Tactic Number</InputLabel>
                  <Select
                    labelId="tacticNo_label"
                    id="tacticNo"
                    name="tacticNo"
                    value={params["tacticNo"]}
                    onChange={updateField}
                  >
                    {_.map([...Array(13).keys()], tacticNo => {
                      return (
                        <MenuItem value={tacticNo} key={tacticNo}>
                          Tactic{tacticNo}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {!_.isEmpty(messages.tacticNo) && (
                    <FormHelperText>{messages.tacticNo}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="size"
                  name="size"
                  label="問題数"
                  fullWidth
                  type="number"
                  value={params["size"]}
                  error={!_.isEmpty(messages["size"])}
                  helperText={messages["size"]}
                  onChange={updateField}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleGet}
                  >
                    Get
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} key="sudoku_list">
          <SudokuList
            sudokus={problems}
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="copy" aria-label="copy" placement="top">
          <Fab
            color="inherit"
            aria-label="download"
            className={classes.fab}
            onClick={() => handleCopy()}
          >
            <FileCopyIcon />
          </Fab>
        </Tooltip>
      </Box>
    </>
  )
}
