import React, { useState, useEffect } from "react"
import _ from "lodash"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import { useAuth0 } from "./react-auth0-spa"
import { useStyles } from "../utils/styles"
import { fetchApi } from "../utils/fetchApi"

const CountPaper = (count, title, key, fixedHeightPaper) => {
  return (
    <Grid item xs={12} md={3} key={key}>
      <Paper className={fixedHeightPaper}>
        <Typography variant="h6" color="primary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          問題数
        </Typography>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {count.toLocaleString()}
        </Typography>
      </Paper>
    </Grid>
  )
}

const Stats = ({ stats }) => {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  if (_.isEmpty(stats)) {
    return null
  }
  const items = _.map(stats, (count, index) => {
    return CountPaper(count, `Tactic${index}`, index + 1, fixedHeightPaper)
  })
  items.unshift(CountPaper(_.sum(stats), "Total", 0, fixedHeightPaper))
  return items
}

const DashboardTop = () => {
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()
  const [stats, setStats] = useState([])

  useEffect(() => {
    let cleanupFlag = false
    if (loading || !isAuthenticated) {
      return
    }

    const fetchStats = async () => {
      const token = await getTokenSilently()
      const responseData = await fetchApi(token, "/stats")
      if (!cleanupFlag) {
        setStats(responseData)
      }
    }
    fetchStats()
    return () => {
      cleanupFlag = true
    }
  }, [loading, isAuthenticated, getTokenSilently, setStats])

  return (
    <Grid container spacing={2}>
      <Stats stats={stats} />
    </Grid>
  )
}

export default DashboardTop
