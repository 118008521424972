import React from "react"
import { Link } from "@reach/router"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import GridOnIcon from "@material-ui/icons/GridOn"
import DashboardIcon from "@material-ui/icons/Dashboard"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import BusinessIcon from "@material-ui/icons/Business"

import { useAuth0 } from "./react-auth0-spa"

export default function MainListItems() {
  const { isAuthenticated } = useAuth0()

  return (
    <List>
      <ListItem button component={Link} to="/dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      {isAuthenticated && (
        <ListItem button component={Link} to="/dashboard/publications">
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary="Publications" />
        </ListItem>
      )}

      {isAuthenticated && (
        <ListItem button component={Link} to="/dashboard/publishers">
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Publishers" />
        </ListItem>
      )}

      {isAuthenticated && (
        <ListItem button component={Link} to="/dashboard/problems">
          <ListItemIcon>
            <GridOnIcon />
          </ListItemIcon>
          <ListItemText primary="Problems" />
        </ListItem>
      )}
    </List>
  )
}
