import React from "react"
import { Router, Location } from "@reach/router"
import clsx from "clsx"
import CssBaseline from "@material-ui/core/CssBaseline"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import Container from "@material-ui/core/Container"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"

import MainListItems from "../components/listItems"
import { Auth0Provider } from "../components/react-auth0-spa"
import AppBar from "../components/appbar"
import Copyright from "../components/copyright"
import DashboardTop from "../components/dashboardTop"
import Publications from "../components/Publications"
import Publishers from "../components/Publishers"
import Problems from "../components/Problems"
import { useStyles } from "../utils/styles"
import config from "../auth_config.json"
import { siteURL } from "../config/config"

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const Dashboard = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)
  const handleDrawerClose = () => {
    setOpen(false)
  }
  const App = ({ children }) => (
    <>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        {children}
      </Container>
      <Copyright />
    </>
  )

  return (
    <div className={classes.root}>
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        audience={config.audience}
        redirect_uri={
          process.env.NODE_ENV === "development"
            ? "http://localhost:8000/dashboard/"
            : siteURL + "dashboard/"
        }
        onRedirectCallback={onRedirectCallback}
      >
        <CssBaseline />
        <Location>
          {({ location }) => {
            return <AppBar open={open} setOpen={setOpen} location={location} />
          }}
        </Location>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <MainListItems />
        </Drawer>
        <Router className={classes.content}>
          <App path="dashboard">
            <DashboardTop path="/" />
            <Publications path="publications/*" />
            <Publishers path="publishers/*" />
            <Problems path="problems/*" />
          </App>
        </Router>
      </Auth0Provider>
    </div>
  )
}

export default Dashboard
