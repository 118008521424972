import React, { useState, useEffect } from "react"
import { Router, navigate, Link } from "@reach/router"
import _ from "lodash"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import Fab from "@material-ui/core/Fab"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import VisibilityIcon from "@material-ui/icons/Visibility"

import { useAuth0 } from "./react-auth0-spa"
import { useStyles } from "../utils/styles"
import { fetchApi } from "../utils/fetchApi"
import DeleteDialog from "./DeleteDialog"
import FormButtons from "./FormButtons"
import SudokuList from "./SudokuList"

const PublicationsTable = ({ rows, handleDelete }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [targetId, setTargetId] = useState(0)

  const handleDeleteClick = id => e => {
    setOpen(true)
    setTargetId(id)
  }

  const handleOk = e => {
    handleDelete(targetId)
    setOpen(false)
  }

  if (_.isEmpty(rows)) {
    return null
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>出版日</TableCell>
              <TableCell>価格</TableCell>
              <TableCell>ISBN</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.published_at}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>{row.isbn}</TableCell>
                <TableCell align="right">
                  <Link to={`show/${row.id}`}>
                    <IconButton aria-label="show">
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </Link>
                  <Link to={`edit/${row.id}`}>
                    <IconButton aria-label="edit">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Link>
                  <IconButton
                    aria-label="delete"
                    onClick={handleDeleteClick(row.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <DeleteDialog open={open} setOpen={setOpen} handleOk={handleOk} />
    </>
  )
}

const List = () => {
  const classes = useStyles()
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()
  const [publications, setPublications] = useState([])

  const handleDelete = async id => {
    const token = await getTokenSilently()
    const responseData = await fetchApi(token, `/publications/${id}`, "DELETE")
    console.log(responseData)
    setPublications(
      _.filter(publications, publication => {
        return publication.id !== id
      })
    )
  }

  useEffect(() => {
    let cleanupFlag = false
    if (loading || !isAuthenticated) {
      navigate("/dashboard")
      return
    }

    const fetchPublications = async () => {
      const token = await getTokenSilently()
      const responseData = await fetchApi(token, "/publications")
      if (!cleanupFlag) {
        setPublications(responseData)
      }
    }
    fetchPublications()
    return () => {
      cleanupFlag = true
    }
  }, [loading, isAuthenticated, getTokenSilently, setPublications])

  return loading || !isAuthenticated ? null : (
    <>
      <PublicationsTable
        rows={publications}
        path="/"
        handleDelete={handleDelete}
      />
      <Box display="flex" justifyContent="flex-end">
        <Fab
          color="secondary"
          aria-label="add"
          className={classes.fab}
          onClick={() => navigate(window.location.pathname + "/create")}
        >
          <AddIcon />
        </Fab>
      </Box>
    </>
  )
}

const PublicationForm = ({
  title,
  buttonLabel,
  publishers,
  defaultData,
  handleChange,
  handleClick,
}) => {
  const classes = useStyles()
  const [messages, setMessages] = useState({
    publisher_id: "",
    name: "",
    published_at: "",
    price: "",
    isbn: "",
  })

  const updateField = e => {
    setMessages({ ...messages, [e.target.name]: e.target.validationMessage })
    handleChange(e)
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl
            required
            fullWidth
            error={!_.isEmpty(messages.publisher_id)}
          >
            <InputLabel id="publisher_id_label">出版社</InputLabel>
            <Select
              labelId="publisher_id_label"
              id="publisher_id"
              name="publisher_id"
              value={defaultData.publisher_id}
              onChange={updateField}
            >
              {_.map(publishers, publisher => {
                return (
                  <MenuItem value={publisher.id} key={publisher.id}>
                    {publisher.name}
                  </MenuItem>
                )
              })}
            </Select>
            {!_.isEmpty(messages.publisher_id) && (
              <FormHelperText>{messages.publisher_id}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="name"
            name="name"
            label="名前"
            value={defaultData.name}
            fullWidth
            error={!_.isEmpty(messages.name)}
            helperText={messages.name}
            onChange={updateField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="published_at"
            name="published_at"
            label="出版日"
            type="date"
            value={defaultData.published_at}
            fullWidth
            error={!_.isEmpty(messages.published_at)}
            helperText={messages.published_at}
            onChange={updateField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="price"
            name="price"
            label="価格"
            type="number"
            value={defaultData.price}
            fullWidth
            error={!_.isEmpty(messages.price)}
            helperText={messages.price}
            onChange={updateField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="isbn"
            name="isbn"
            label="ISBN"
            type="text"
            value={defaultData.isbn}
            fullWidth
            error={!_.isEmpty(messages.isbn)}
            helperText={messages.isbn}
            onChange={updateField}
          />
        </Grid>
        <Grid item xs={12}>
          <FormButtons handleClick={handleClick} label={buttonLabel} />
        </Grid>
      </Grid>
    </Paper>
  )
}
const Create = () => {
  const [data, setData] = useState({
    publisher_id: 0,
    name: "",
    published_at: "",
    price: "",
    isbn: "",
  })
  const [publishers, setPublishers] = useState([])
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()

  const handleCreate = async () => {
    const token = await getTokenSilently()
    await fetchApi(token, "/publications", "POST", data)
    window.history.back()
  }

  const handleChange = e => {
    if (_.isEmpty(e.target.validationMessage)) {
      setData({ ...data, [e.target.name]: e.target.value })
    }
  }

  useEffect(() => {
    let cleanupFlag = false
    if (loading || !isAuthenticated) {
      navigate("/dashboard")
      return
    }

    const fetchPublishers = async () => {
      const token = await getTokenSilently()
      const responseData = await fetchApi(token, "/publishers")
      if (!cleanupFlag) {
        setPublishers(responseData)
      }
    }
    fetchPublishers()
    return () => {
      cleanupFlag = true
    }
  }, [loading, isAuthenticated, getTokenSilently, setPublishers])

  return _.isEmpty(publishers) || loading || !isAuthenticated ? null : (
    <PublicationForm
      title="新規書籍を作成"
      buttonLabel="Create"
      publishers={publishers}
      defaultData={data}
      handleChange={handleChange}
      handleClick={handleCreate}
    />
  )
}

const Edit = ({ id }) => {
  const [data, setData] = useState({})
  const [publishers, setPublishers] = useState([])
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()

  const handleUpdate = async () => {
    const token = await getTokenSilently()
    const responseData = await fetchApi(
      token,
      `/publications/${id}`,
      "PUT",
      data
    )
    console.log(responseData)
    window.history.back()
  }

  const handleChange = e => {
    if (_.isEmpty(e.target.validationMessage)) {
      setData({ ...data, [e.target.name]: e.target.value })
    }
  }

  useEffect(() => {
    let cleanupFlag = false
    if (loading || !isAuthenticated) {
      navigate("/dashboard")
      return
    }

    const fetchPublishers = async () => {
      const token = await getTokenSilently()
      const responseData = await fetchApi(token, "/publishers")
      if (!cleanupFlag) {
        setPublishers(responseData)
      }
    }
    fetchPublishers()
    return () => {
      cleanupFlag = true
    }
  }, [loading, isAuthenticated, getTokenSilently, setPublishers])

  useEffect(() => {
    let cleanupFlag = false
    if (loading || !isAuthenticated) {
      return
    }

    const fetchPublication = async () => {
      const token = await getTokenSilently()
      const responseData = await fetchApi(token, `/publications/${id}`)
      console.log(responseData)
      if (!cleanupFlag) {
        setData(responseData)
      }
    }
    fetchPublication()
    return () => {
      cleanupFlag = true
    }
  }, [loading, isAuthenticated, getTokenSilently, setData, id])

  return _.isEmpty(data) ||
    _.isEmpty(publishers) ||
    loading ||
    !isAuthenticated ? null : (
    <PublicationForm
      title="書籍情報を更新"
      buttonLabel="Update"
      publishers={publishers}
      defaultData={data}
      handleChange={handleChange}
      handleClick={handleUpdate}
    />
  )
}

const PublicationInfoTable = ({ data }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" gutterBottom>
        書籍情報
      </Typography>
      <Table size="small" aria-label="pulication info">
        <TableBody>
          <TableRow key="name">
            <TableCell component="th" scope="row">
              書籍名
            </TableCell>
            <TableCell>{data.name}</TableCell>
          </TableRow>
          <TableRow key="publisher_name">
            <TableCell component="th" scope="row">
              出版社名
            </TableCell>
            <TableCell>{data.publisher.name}</TableCell>
          </TableRow>
          <TableRow key="published_at">
            <TableCell component="th" scope="row">
              出版日
            </TableCell>
            <TableCell>{data.published_at}</TableCell>
          </TableRow>
          <TableRow key="price">
            <TableCell component="th" scope="row">
              価格
            </TableCell>
            <TableCell>{data.price}</TableCell>
          </TableRow>
          <TableRow key="isbn">
            <TableCell component="th" scope="row">
              ISBN
            </TableCell>
            <TableCell>{data.isbn}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

const UploadDialog = ({ open, setOpen, handleOk }) => {
  const classes = useStyles()
  const [message, setMessage] = useState("")
  const [data, setData] = useState()

  const handleCancel = e => {
    setOpen(false)
  }

  const handleChange = e => {
    try {
      const json = JSON.parse(e.target.value)
      setMessage("")
      setData(json)
    } catch (e) {
      setMessage("invalid json format")
      setData(null)
    }
  }

  const handleUpload = e => {
    setOpen(false)
    if (_.isEmpty(data)) {
      console.error("invalid json format")
    } else {
      handleOk(data)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <DialogContentText>
          追加する問題を JSON 形式でペーストしてください
        </DialogContentText>
        <TextField
          id="json"
          name="json"
          label="json"
          multiline={true}
          rows="10"
          variant="outlined"
          error={!_.isEmpty(message)}
          helperText={message}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} className={classes.button}>
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          color="primary"
          className={classes.button}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Show = ({ id }) => {
  const classes = useStyles()
  const [data, setData] = useState({})
  const [open, setOpen] = useState(false)
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()
  const [selected, setSelected] = useState([])

  const clearSelected = () => {
    setSelected([])
  }

  const handleDelete = async () => {
    const newSudokus = _.filter(data.sudokus, sudoku => {
      return selected.indexOf(sudoku.id) === -1
    })
    const token = await getTokenSilently()
    const responseData = await fetchApi(
      token,
      `/publications/${id}`,
      "POST",
      _.map(newSudokus, sudoku => {
        return sudoku.id
      })
    )
    console.log(responseData)
    setData(responseData)
    clearSelected()
  }

  const handleAdd = async json => {
    const token = await getTokenSilently()
    const responseData = await fetchApi(
      token,
      `/publications/${id}`,
      "POST",
      _.uniq(
        _.map(_.concat(data.sudokus, json), sudoku => {
          return sudoku.id
        })
      )
    )
    console.log(responseData)
    setData(responseData)
    clearSelected()
  }

  useEffect(() => {
    let cleanupFlag = false
    if (loading || !isAuthenticated) {
      navigate("/dashboard")
      return
    }

    const fetchPublication = async () => {
      const token = await getTokenSilently()
      const responseData = await fetchApi(token, `/publications/${id}`)
      console.log(responseData)
      if (!cleanupFlag) {
        setData(responseData)
      }
    }
    fetchPublication()
    return () => {
      cleanupFlag = true
    }
  }, [loading, isAuthenticated, getTokenSilently, setData, id])

  const handleCopy = () => {
    if (navigator.clipboard) {
      const copied = JSON.parse(JSON.stringify(data.sudokus))
      for (let sudoku of copied) {
        sudoku.json = JSON.parse(sudoku.json)
        sudoku.hint.json = JSON.parse(sudoku.hint.json)
      }
      navigator.clipboard.writeText(JSON.stringify(copied))
    }
  }

  return _.isEmpty(data) || loading || !isAuthenticated ? null : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} key="publication_info">
          <PublicationInfoTable data={data} />
        </Grid>
        <Grid item xs={12} key="sudoku_list">
          <SudokuList
            sudokus={data.sudokus}
            handleDelete={handleDelete}
            selected={selected}
            setSelected={setSelected}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Box mr={1}>
          <Tooltip title="copy" aria-label="copy" placement="top">
            <Fab
              color="inherit"
              aria-label="download"
              className={classes.fab}
              onClick={() => handleCopy()}
            >
              <FileCopyIcon />
            </Fab>
          </Tooltip>
        </Box>
        <Fab
          color="secondary"
          aria-label="add"
          className={classes.fab}
          onClick={() => setOpen(true)}
        >
          <AddIcon />
        </Fab>
      </Box>
      <UploadDialog open={open} setOpen={setOpen} handleOk={handleAdd} />
    </>
  )
}

export default function Publications() {
  return (
    <Router>
      <List path="/" />
      <Create path="create" />
      <Edit path="edit/:id" />
      <Show path="show/:id" />
    </Router>
  )
}
