import React from "react"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import { useStyles } from "../utils/styles"

const handleBackDefault = () => {
  window.history.back()
}

export default function FormButtons({
  handleBack = handleBackDefault,
  handleClick,
  label,
}) {
  const classes = useStyles()

  return (
    <Typography align="center">
      <Button onClick={handleBack} className={classes.button}>
        Back
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={classes.button}
      >
        {label}
      </Button>
    </Typography>
  )
}
