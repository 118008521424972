import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import WarningIcon from "@material-ui/icons/Warning"

import { useStyles } from "../utils/styles"

export default function DeleteDialog({ open, setOpen, handleOk }) {
  const classes = useStyles()

  const handleCancel = e => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <WarningIcon color="error" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>本当に削除してもよろしいですか？</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} className={classes.button}>
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" className={classes.button}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
