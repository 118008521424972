import React from "react"
import clsx from "clsx"
import _ from "lodash"
import MaterialAppBar from "@material-ui/core/AppBar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import MenuIcon from "@material-ui/icons/Menu"

import { useStyles } from "../utils/styles"
import { useAuth0 } from "../components/react-auth0-spa"

const AppBarTitle = ({ location }) => {
  const classes = useStyles()
  const splitedPath = _.split(location.pathname, "/")
  const filteredPath = _.filter(splitedPath, p => {
    return !_.isEmpty(p)
  })

  const handleBack = () => {
    window.history.back()
  }

  return (
    <Typography
      component="h1"
      variant="h6"
      color="inherit"
      noWrap
      className={classes.title}
    >
      {filteredPath.length > 2 && (
        <IconButton aria-label="back" onClick={handleBack}>
          <ArrowBackIcon className={classes.backIcon} />
        </IconButton>
      )}
      Puzzle.dev Management Dashboard
    </Typography>
  )
}

const AppBar = ({ open, setOpen, location }) => {
  const classes = useStyles()
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const isDashboardRoot = () => {
    const splitedPath = _.split(location.pathname, "/")
    const filteredPath = _.filter(splitedPath, p => {
      return !_.isEmpty(p)
    })
    return filteredPath.length === 1 && filteredPath[0] === "dashboard"
  }

  return (
    <MaterialAppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <AppBarTitle location={location} />
        {!isAuthenticated && isDashboardRoot() && (
          <Button color="inherit" onClick={() => loginWithRedirect({})}>
            Login
          </Button>
        )}
        {isAuthenticated && (
          <Button color="inherit" onClick={() => logout()}>
            Logout
          </Button>
        )}
      </Toolbar>
    </MaterialAppBar>
  )
}

export default AppBar
