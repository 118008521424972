import React from "react"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"

import { siteTitle } from "../config/config"

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://puzzle.dev/">
        {siteTitle}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export default Copyright
