import React, { useState, useEffect } from "react"
import { Router, navigate, Link } from "@reach/router"
import _ from "lodash"
import Box from "@material-ui/core/Box"
import Fab from "@material-ui/core/Fab"
import IconButton from "@material-ui/core/IconButton"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"

import { useAuth0 } from "./react-auth0-spa"
import FormButtons from "./FormButtons"
import DeleteDialog from "./DeleteDialog"
import { useStyles } from "../utils/styles"
import { fetchApi } from "../utils/fetchApi"

const PublishersTable = ({ rows, handleDelete }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [targetId, setTargetId] = useState(0)

  const handleDeleteClick = id => e => {
    setOpen(true)
    setTargetId(id)
  }

  const handleOk = e => {
    handleDelete(targetId)
    setOpen(false)
  }

  if (_.isEmpty(rows)) {
    return null
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>郵便番号</TableCell>
              <TableCell>住所</TableCell>
              <TableCell>電話番号</TableCell>
              <TableCell>URL</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.postal_code}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.phone_number}</TableCell>
                <TableCell>{row.url}</TableCell>
                <TableCell align="right">
                  <Link to={`edit/${row.id}`}>
                    <IconButton aria-label="edit">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Link>
                  <IconButton
                    aria-label="delete"
                    onClick={handleDeleteClick(row.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <DeleteDialog open={open} setOpen={setOpen} handleOk={handleOk} />
    </>
  )
}

const List = () => {
  const classes = useStyles()
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()
  const [publishers, setPublishers] = useState([])

  const handleDelete = async id => {
    const token = await getTokenSilently()
    const responseData = await fetchApi(token, `/publishers/${id}`, "DELETE")
    console.log(responseData)
    setPublishers(
      _.filter(publishers, publisher => {
        return publisher.id !== id
      })
    )
  }

  useEffect(() => {
    let cleanupFlag = false
    if (loading || !isAuthenticated) {
      navigate("/dashboard")
      return
    }

    const fetchPublishers = async () => {
      const token = await getTokenSilently()
      const responseData = await fetchApi(token, "/publishers")
      if (!cleanupFlag) {
        setPublishers(responseData)
      }
    }
    fetchPublishers()
    return () => {
      cleanupFlag = true
    }
  }, [loading, isAuthenticated, getTokenSilently, setPublishers])

  return loading || !isAuthenticated ? null : (
    <>
      <PublishersTable rows={publishers} path="/" handleDelete={handleDelete} />
      <Box display="flex" justifyContent="flex-end">
        <Fab
          color="secondary"
          aria-label="add"
          className={classes.fab}
          onClick={() => navigate(window.location.pathname + "/create")}
        >
          <AddIcon />
        </Fab>
      </Box>
    </>
  )
}

const PublisherForm = ({
  title,
  buttonLabel,
  defaultData,
  handleChange,
  handleClick,
}) => {
  const classes = useStyles()
  const [messages, setMessages] = useState({
    name: "",
    postal_code: "",
    address: "",
    phone_number: "",
    url: "",
  })

  const updateField = e => {
    setMessages({ ...messages, [e.target.name]: e.target.validationMessage })
    handleChange(e)
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="name"
            name="name"
            label="名前"
            defaultValue={defaultData.name}
            fullWidth
            error={!_.isEmpty(messages.name)}
            helperText={messages.name}
            onChange={updateField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="postal_code"
            name="postal_code"
            label="郵便番号"
            type="number"
            defaultValue={defaultData.postal_code}
            fullWidth
            error={!_.isEmpty(messages.postal_code)}
            helperText={messages.postal_code}
            onChange={updateField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="住所"
            defaultValue={defaultData.address}
            fullWidth
            error={!_.isEmpty(messages.address)}
            helperText={messages.address}
            onChange={updateField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="phone_number"
            name="phone_number"
            label="電話番号"
            type="number"
            defaultValue={defaultData.phone_number}
            fullWidth
            error={!_.isEmpty(messages.phone_number)}
            helperText={messages.phone_number}
            onChange={updateField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="url"
            name="url"
            label="URL"
            type="url"
            defaultValue={defaultData.url}
            fullWidth
            error={!_.isEmpty(messages.url)}
            helperText={messages.url}
            onChange={updateField}
          />
        </Grid>
        <Grid item xs={12}>
          <FormButtons handleClick={handleClick} label={buttonLabel} />
        </Grid>
      </Grid>
    </Paper>
  )
}

const Create = () => {
  const [data, setData] = useState({})
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()

  const handleCreate = async () => {
    const token = await getTokenSilently()
    await fetchApi(token, "/publishers", "POST", data)
    window.history.back()
  }

  const handleChange = e => {
    if (_.isEmpty(e.target.validationMessage)) {
      setData({ ...data, [e.target.name]: e.target.value })
    }
  }

  return loading || !isAuthenticated ? null : (
    <PublisherForm
      title="新規出版社を作成"
      buttonLabel="Create"
      defaultData={data}
      handleChange={handleChange}
      handleClick={handleCreate}
    />
  )
}

const Edit = ({ id }) => {
  const [data, setData] = useState({})
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()

  useEffect(() => {
    let cleanupFlag = false
    if (loading || !isAuthenticated) {
      navigate("/dashboard")
      return
    }

    const fetchPublisher = async () => {
      const token = await getTokenSilently()
      const responseData = await fetchApi(token, `/publishers/${id}`)
      if (!cleanupFlag) {
        setData(responseData)
      }
    }
    fetchPublisher()
    return () => {
      cleanupFlag = true
    }
  }, [loading, isAuthenticated, getTokenSilently, setData, id])

  const handleUpdate = async () => {
    const token = await getTokenSilently()
    const responseData = await fetchApi(token, `/publishers/${id}`, "PUT", data)
    console.log(responseData)
    window.history.back()
  }

  const handleChange = e => {
    if (_.isEmpty(e.target.validationMessage)) {
      setData({ ...data, [e.target.name]: e.target.value })
    }
  }

  return _.isEmpty(data) || loading || !isAuthenticated ? null : (
    <PublisherForm
      title="出版社を編集"
      buttonLabel="Update"
      defaultData={data}
      handleChange={handleChange}
      handleClick={handleUpdate}
    />
  )
}

export default function Publishers() {
  return (
    <Router>
      <List path="/" />
      <Create path="create" />
      <Edit path="edit/:id" />
    </Router>
  )
}
